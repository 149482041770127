$breakpoints: (
    xs: 375px,
    s: 544px,
    m: 768px,
    l: 992px,
    xl: 1024px,
    xxl: 1170px,
);

// l: 1440px,
// xl: 1680px,
// xxl: 1930px,

$white: #fff;
$black: #000;

$blue: #007fff;
$violet: #a47df7;
$purple: #6f28e2;

$grey-90: #333333;
$grey-80: #555555;
$grey-75: #b5b5b5;
$grey-70: #666666;
$grey-60: #777777;
$grey-50: #999999;
$grey-40: #cccccc;
$grey-30: #f2f2f2;
$grey-20: #f1f1f1;

$spotify-black: rgb(40, 40, 40);

$font-family-regular: 'InterTight Regular', sans-serif;
$font-family-semibold: 'InterTight SemiBold', sans-serif;
$font-family-bold: 'InterTight Bold', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

$transition-ease-out-expo: 0.7s cubic-bezier(0.19, 1, 0.22, 1);
$transition-linear: 0.2s linear;
$transition-ease: 0.2s ease;
